import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

import MetaImage from '../images/og/contact.jpg'

import './lets-talk.less'

class ContactPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      content: 'hidden',
      box2: 'hidden',
      box3: 'hidden',
    }
  }

  componentDidMount = () => {
    document.body.classList.add('remove')
    window.scroll(0, 0)
    setTimeout(() => {
      document.body.classList.remove('cover', 'remove', 'white')
    }, 800)
    setTimeout(() => {
      this.setState({ content: 'visible' })
    }, 300)
    document.body.classList.remove('scroll-stop')
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Contact"
          image={MetaImage}
          description="Get in touch and learn how we can help transform your business."
        />

        <section className="contact hero" id="hero">
          <div className="uk-container">
            <div
              className={'contact-content ' + this.state.content}
              id="contact_content"
            >
              <div className="section">
                <span>General Inquiries &amp; new business</span>
                <p>
                  <a href="mailto:hello@ensodigital.ca">hello@ensodigital.ca</a>
                </p>
                <p>
                  <a href="tel:+16479579922">(647) 957-9922</a>
                </p>
              </div>

              <div className="section">
                <span>Location</span>
                <address>
                  <span>111 PETER ST SUITE 700,</span>
                  <span>TORONTO, ON</span>
                </address>
              </div>

              <div className="section">
                <span>Follow us</span>
                <p>
                  <a
                    href={'https://www.instagram.com/ensodigital'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                </p>
                <p>
                  <a
                    href={'https://www.facebook.com/ensodigital.ca'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                </p>
                <p>
                  <a
                    href={'https://www.linkedin.com/company/ensodigital'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Linkedin
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default ContactPage
